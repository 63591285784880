<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/images/marketing/screenshots/Notifications_mobile.png';
import Action from '@/modules/marketing/MarketingAction.vue';
import { Slider, SliderItem } from 'vue-easy-slider';

@Component({
  components: {
    Action,
    Slider,
    SliderItem,
  },
  layout: 'default',
  metaInfo: {
    title: 'Guest Information ',
    // override the parent template and just use the above title only
  },
})
export default class GuestInfo extends Vue {}
</script>

<template>
  <main>
    <section class="py-24 md:pt-24 md:pb-12">
      <div class="bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="container px-4 mx-auto">
            <div class="lg:text-center mb-20">
              <h1 class="leading-6 text-lg text-red-600 font-semibold tracking-wide uppercase">Guest Information</h1>
              <h2 class="my-3 md:text-4xl text-gray-900 font-bold font-heading"> A better way to check-in. </h2>
              <p class="mt-4 max-w-2xl text-xl lg:mx-auto text-gray-500">
                With Check-In Buddy you no longer need to wait to check-in. Skip the queues at reception and check-in prior to travel.
              </p>
            </div>

            <!--Slider-->
            <div class="slider-wrap">
              <Slider class="guest-slider" height="550px" animation="fade" :duration="5000" :speed="1000">
                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="absolute" style="top: 3%; left: 5%; width: 90%; height: 94%">
                      <img
                        class="mx-auto object-cover w-ful h-full hoverZoomLink"
                        src="@/assets/images/marketing/screenshots/checkin_step1.jpg"
                        alt="guest_check-in_mockup"
                      />
                    </div>
                    <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="" />
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="absolute" style="top: 3%; left: 5%; width: 90%; height: 94%">
                      <img
                        class="mx-auto object-cover w-ful h-full hoverZoomLink"
                        src="@/assets/images/marketing/screenshots/checkin_step2.jpg"
                        alt="guest_check-in_mockup"
                      />
                    </div>
                    <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="mockup_image" />
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="absolute" style="top: 3%; left: 5%; width: 90%; height: 94%">
                      <img
                        class="mx-auto object-cover w-ful h-full hoverZoomLink"
                        src="@/assets/images/marketing/screenshots/checkin_step3.jpg"
                        alt="guest_check-in_mockup"
                      />
                    </div>
                    <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="mockup_image" />
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="absolute" style="top: 3%; left: 5%; width: 90%; height: 94%">
                      <img
                        class="mx-auto object-cover w-ful h-full hoverZoomLink"
                        src="@/assets/images/marketing/screenshots/checkin_step4.jpg"
                        alt="guest_check-in_mockup"
                      />
                    </div>
                    <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="mockup_image" />
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="absolute" style="top: 3%; left: 5%; width: 90%; height: 94%">
                      <img
                        class="mx-auto object-cover w-ful h-full hoverZoomLink"
                        src="@/assets/images/marketing/screenshots/checkin_step5.jpg"
                        alt="guest_check-in_mockup"
                      />
                    </div>
                    <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="mockup_image" />
                  </div>
                </SliderItem>
              </Slider>
            </div>

            <div class="mt-10 mb-20">
              <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Check-in prior to arrival</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    An accommodation will email or SMS you an invitation to check-in online prior to your arrival. You can provide your
                    personal information, upload a picture of your identity document, and digitally sign the necessary agreements from
                    wherever you are – all from your own device.
                  </dd>
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Receive useful information before you arrive</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Included in your invitation to check-in will be useful documents and information about the accommodation, such as
                    directions, a Google Maps pin, spa treatment menus, restaurant menus, etc.
                  </dd>
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Complete a digital health check</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Stay safe by completing an accommodation’s COVID-19 health screening questionnaire through Check-In Buddy. On the day of
                    your arrival, if an accommodation requires a health check, you will receive an email or SMS invitation to complete the
                    questionnaire.
                  </dd>
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Skip queues</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Checking in beforehand means you get to skip the queue! When you arrive at your accommodation all that is left is to
                    collect your key, have your temperature checked if required, and voilà... More time to enjoy your holiday!
                  </dd>
                </div>
              </dl>
            </div>

            <div class="py-16 overflow-hidden lg:py-24">
              <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div class="relative">
                  <h2 class="my-3 md:text-4xl lg:mx-auto text-center text-gray-900 font-bold font-heading"> Tips for checking-in </h2>
                  <p class="mt-4 max-w-2xl text-xl lg:mx-auto text-center text-gray-500">
                    Checking-in with Check-In Buddy is simple and should only take a few minutes of your time. To help you along we have put
                    together some useful tips.
                  </p>
                </div>

                <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div class="relative">
                    <h3 class="text-2xl text-gray-900 font-bold font-heading sm:text-3xl"> Info you’ll need to check-in </h3>
                    <p class="mt-3 text-lg text-gray-500">
                      Make sure you have the following information at hand before you check-in, to speed up the process:
                    </p>

                    <dl class="mt-10 space-y-10">
                      <div class="relative">
                        <dt>
                          <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                            <svg
                              class="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                              />
                            </svg>
                          </div>
                          <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Documents</p>
                        </dt>
                        <dd class="mt-2 ml-16 text-base text-gray-500">
                          A copy of your identification document or passport. If you are checking in on your mobile device you can take a
                          photo of this and upload it during check-in directly from your gallery.
                        </dd>
                      </div>

                      <div class="relative">
                        <dt>
                          <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                            <svg
                              class="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                              />
                            </svg>
                          </div>
                          <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Primary booking guest</p>
                        </dt>
                        <dd class="mt-2 ml-16 text-base text-gray-500">
                          If you are the primary guest of the group and the establishment does not have the details of those travelling with
                          you, you might be asked to invite the other guests, linked to your reservation, in the final step. This can be
                          done with their email address or cell phone number.
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                    <div class="w-full px-4 mt-12 md:mt-0 order-last md:order-last">
                      <div class="flex flex-row order-last md:order-last justify-center md:justify-last">
                        <div class="relative">
                          <div class="absolute bg-red-600" style="top: 3%; left: 7%; width: 87%; height: 94%">
                            <img
                              class="object-cover w-full h-full"
                              src="@/assets/images/marketing/screenshots/healthscreening1.jpg"
                              alt="mockup_image"
                            />
                          </div>
                          <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="relative mt-12 sm:mt-16 lg:mt-24">
                  <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div class="lg:col-start-2">
                      <h3 class="text-2xl text-gray-900 font-bold font-heading sm:text-3xl"> Providing additional optional information </h3>
                      <p class="mt-3 text-lg text-gray-500">
                        When checking in, an accommodation may request additional information from you. It is optional, but we recommend you
                        complete the entire check-in form, as the information you provide may be useful in certain situations, depending on
                        where you are staying.
                      </p>

                      <dl class="mt-10 space-y-10">
                        <div class="relative">
                          <dt>
                            <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                              <!-- Heroicon name: outline/globe-alt -->
                              <svg
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                                />
                              </svg>
                            </div>
                            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Lodges and Safari Camps</p>
                          </dt>
                          <dd class="mt-2 ml-16 text-base text-gray-500">
                            If you are staying at a remote lodge or safari camp, we recommend that you provide as much information as
                            possible. Such as:
                            <ul>
                              <li style="list-style: circle"> Medical insurance details in case of an emergency</li>
                              <li style="list-style: circle"> Dietary requirements as special catering may be needed</li>
                              <li style="list-style: circle"> Any special access requirements</li>
                              <li style="list-style: circle"> Approximate arrival times as staff may have to meet you at the gate</li>
                            </ul>
                          </dd>
                        </div>

                        <div class="relative">
                          <dt>
                            <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                              <!-- Heroicon name: outline/globe-alt -->
                              <svg
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                                />
                              </svg>
                            </div>
                            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Hotels</p>
                          </dt>
                          <dd class="mt-2 ml-16 text-base text-gray-500">
                            If you are staying at a hotel, there is generally no need for any additional information. However, if you
                            require anything specific from your accommodation, like special assistance due to physical limitations or you
                            would like to inform the hotel of a special occasion, you can provide this information while checking in.
                          </dd>
                        </div>

                        <div class="relative">
                          <dt>
                            <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                              <!-- Heroicon name: outline/globe-alt -->
                              <svg
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                                />
                              </svg>
                            </div>
                            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Self-Catering, Guest Houses, and B&Bs</p>
                          </dt>
                          <dd class="mt-2 ml-16 text-base text-gray-500">
                            Generally, with smaller establishments, it is important to indicate your approximate time of arrival so that
                            someone can meet you at the accommodation with a key. In addition, guest Houses and B&Bs will almost always
                            provide breakfast, so be sure to indicate any special dietary requirements during check-in.
                          </dd>
                        </div>
                      </dl>
                    </div>

                    <!-- <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                      <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404" aria-hidden="true">
                        <defs>
                          <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                      </svg>
                      <img class="relative mx-auto" width="490" src="https://tailwindui.com/img/features/feature-example-2.png" alt="">
                    </div> -->

                    <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1" aria-hidden="true">
                      <div class="w-full px-4 mt-12 md:mt-0 order-last md:order-last">
                        <div class="flex flex-row order-last md:order-last justify-center md:justify-last">
                          <div class="relative">
                            <div class="absolute bg-red-600" style="top: 3%; left: 7%; width: 87%; height: 94%">
                              <img
                                class="object-cover w-full h-full"
                                src="@/assets/images/marketing/screenshots/bookingconfirmation.jpg"
                                alt="mockup_image"
                              />
                            </div>
                            <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="pt-24 pb-24"
      ><div class="container px-4 mx-auto">
        <h2 class="mb-5 text-center text-3xl md:text-4xl text-blueGray-900 font-bold font-heading">
          Why should I check-in online?
        </h2>
        <div class="flex flex-wrap -mx-3">
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                1
              </span>
              <h4 class="mb-3 text-lg font-semibold font-heading">Check-in in prior to arrival</h4>
              <p class="text-blueGray-400 leading-loose"
                >The accommodation establishment will email or SMS you an invitation to check-in online prior to your arrival, using Check-In Buddy. This allows you to find a few minutes in your day to provide your personal information, upload a picture of your identity document, and digitally sign the necessary agreements, from wherever you are; all from your own device. 
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                  ></path></svg
              ></span>
              <h4 class="mb-3 text-lg font-semibold font-heading">COVID-19 screening and tempreture checks</h4>
              <p class="text-blueGray-400 leading-loose"
                >Your guests will be prompted to complete our integrated health check questionnaire upon arrival and daily thereafter.
                Developed in line with the TBCSA Tourism Industry Standard Protocols.</p
              >
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                  ></path></svg
              ></span>
              <h4 class="mb-3 text-lg font-semibold font-heading">Cloud dashboards for powerful reporting</h4>
              <p class="text-blueGray-400 leading-loose"
                >Manage your establishments and upcoming bookings, know which guests are still to arrive, who is currently checked-in, and
                which guests are checking-out daily.</p
              >
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  ></path></svg
              ></span>
              <h4 class="mb-3 text-lg font-semibold font-heading">Customise and share your information with guests</h4>
              <p class="text-blueGray-400 leading-loose"
                >Load details about your establishment, like contact details, directions and facilities. Give your guests all the
                information that they may need prior to arrival and during their stay.</p
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 md:py-20">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full md:w-2/3 lg:max-w-lg px-4 m-auto">
            <div class="flex h-full">
              <div class="m-auto">
                <div class="mb-8">
                  <h2 class="mb-6 text-4xl md:text-4xl font-bold font-heading">Front Desk QR code for simplified check-in</h2>
                  <p class="leading-loose text-blueGray-400"
                    >Display your unique QR code at reception for walk-in guests, who have not yet checked-in, to scan on arrival. They can
                    simply check-in using Check-In Buddy’s digital, user-friendly platform, allowing for a safe and easy check-in.</p
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-4 mt-12 md:mt-0 order-last md:order-last">
            <div class="flex flex-row order-last md:order-last justify-center md:justify-last">
              <div class="relative">
                <div class="absolute bg-red-600" style="top: 3%; left: 7%; width: 87%; height: 94%"
                  ><img class="object-cover w-full h-full" src="@/assets/images/marketing/screenshots/qrcode.jpg" alt="QR_code_mockup"
                /></div>
                <img class="relative mx-auto" src="@/assets/images/iphone.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-12 md:py-20">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full md:w-2/3 px-4 mt-12 md:mt-0 order-last md:order-first m-auto">
            <div class="flex flex-row order-last md:order-first justify-center md:justify-start">
              <div class="relative">
                <img src="@/assets/images/macbook.png" alt="" />
                <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                  <img
                    class="object-cover w-full h-full hoverZoomLink"
                    src="@/assets/images/marketing/screenshots/dashboard.jpg"
                    alt="organisation_dashboard_mockup"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 lg:max-w-lg px-4">
            <div class="flex h-full">
              <div class="my-auto">
                <div class="mb-8">
                  <h2 class="mb-6 text-3xl md:text-4xl font-bold font-heading"
                    >Secure &amp; POPI compliant cloud storage of guests’ data</h2
                  >
                  <p class="leading-loose text-blueGray-400"
                    >Access your data from anywhere in the world, spot trends, and produce reports. Find and export the exact data you want
                    when you need it. Back up business decisions, assist with COVID-19 tracing efforts, and satisfy audit requests with
                    easily accessible data.</p
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <Action />
  </main>
</template>

<style lang="css">
.slider-wrap {
  position: relative;
  z-index: 10;
}

.slider-obj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slider-btn-right {
  background: none !important;
}

.slider-btn-left {
  background: none !important;
}
</style>
